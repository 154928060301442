import { useForm } from 'react-hook-form';
import { DropDownItem } from '../../common-components/dropdown-component';
import { FormSection } from '../../common-components/generic-form-component';
import { ACCOUNT_ID, ENVIRONMENT, STACK, TITLE, LABEL, PLEASE_PROVIDE_ACCOUNT_INFORMATION } from '../../constants';
import { AccountInput } from '../account-interfaces';

export const createFormSections = (stacksArray: DropDownItem[], envsArray: DropDownItem[]): FormSection[] => {
  const {
    formState: { errors }
  } = useForm<AccountInput>();
  return [
    {
      title: '',
      description: PLEASE_PROVIDE_ACCOUNT_INFORMATION,
      fields: [
        {
          changeMethod: () => {},
          route: 'input.value.title',
          id: TITLE,
          name: TITLE,
          label: '',
          type: 'text',
          autoComplete: 'family-name',
          placeholder: 'Enter title account',
          required: true,
          section: 'account-information'
        },
        {
          changeMethod: () => {},
          route: 'input.value.label',
          id: LABEL,
          name: LABEL,
          label: '',
          type: 'text',
          autoComplete: 'family-name',
          placeholder: 'Enter label account',
          required: true,
          section: 'account-information'
        },
        {
          changeMethod: () => {},
          route: 'input.id',
          id: ACCOUNT_ID,
          name: ACCOUNT_ID,
          label: '',
          type: 'text',
          autoComplete: 'family-name',
          placeholder: 'Enter the AWS account ID',
          required: true,
          section: 'account-information'
        },
        {
          changeMethod: () => {},
          route: 'input.value.env.id',
          id: ENVIRONMENT,
          name: ENVIRONMENT,
          label: ENVIRONMENT,
          type: 'select',
          autoComplete: 'off',
          required: true,
          options: envsArray || [],
          section: 'account-information'
        },
        {
          changeMethod: () => {},
          route: 'input.value.stack.id',
          id: STACK,
          name: STACK,
          label: STACK,
          type: 'select',
          autoComplete: 'off',
          required: true,
          options: stacksArray || [],
          section: 'account-information'
        }
      ],
      categories: [
        {
          id: 'account-information',
          title: 'Account Information',
          col_span: '1'
        }
      ]
    }
  ];
};
