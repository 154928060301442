export const getPagHefestoAccountsConstants = `query getPagHefestoAccountsConstants($input: PaginationInput) {
  getPagHefestoAccountsConstants(input: $input) {
    data {
      id
      value {
        id
        label
        title
        env {
          id
          type
        }
        stack {
          id
          label
        }
      }
      audit {
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
    pageIndex
    pageSize
    totalPages
    totalItems
  }
}`;
