import * as React from 'react';
import { memo, useMemo } from 'react';
import SimpleDropdownComponent from '../common-components/simple-dropdown-component';
import { FILTER_COLUMNS } from '../constants';
import { useStateCallback } from '../hooks/use-state-callback';
import { Account } from '../components/account/view-all-table';
import { VuButton } from '@avaldigitallabs/adl-rockstars-design-system-vulcanus';

const SEARCH_BY_TEXT = 'Search by';
const CLEAR_BUTTON_TEXT = 'x';

export default memo(function FilterComponent(props: FilterProp): JSX.Element {
  const [currentSelect, setSelect] = useStateCallback(FILTER_COLUMNS[0].id);
  const [currentValue, setValue] = React.useState('');

  const handlerValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    props.onSubmit(currentSelect, e.target.value);
  };

  const handlerClear = () => {
    setValue('');
    props.onSubmit(currentSelect, '');
  };

  const setSelectValue = (value: string) => {
    setSelect(value);
  };

  const columns = useMemo(() => FILTER_COLUMNS, []);

  return (
    <div className="flex">
      <div className="relative w-4/12">
        <input
          type="text"
          className="w-full border border-gray-300 rounded-l-md px-4 py-2 focus:outline-none focus:ring-vulcanus_delft_blue-700 focus:border-vulcanus_ring-vulcanus_delft_blue-700 text-black"
          placeholder={'Search...'}
          value={currentValue}
          onChange={handlerValue}
        />
        <div className="absolute inset-y-0 right-0 flex items-center pr-3">
          <button onClick={handlerClear}>X</button>
        </div>
      </div>
      <div>
        <SimpleDropdownComponent
          name={SEARCH_BY_TEXT}
          currentState={currentSelect}
          setCurrentState={setSelectValue}
          items={columns}
        />
      </div>
    </div>
  );
});

export interface FilterProp {
  filterText: string;
  onFilter: (e: React.FormEvent<HTMLInputElement>) => void;
  dataState: Account[];
  setDataState: any;
  onClear: () => void;
  onSubmit: (key: string, value: string) => void;
}
