import * as Joi from 'joi';
export const COLUMNS = [
  {
    id: 'value.id',
    value: {
      id: 'id',
      label: 'label',
      type: 'type'
    }
  }
];

export const FILTER_COLUMNS = [
  {
    id: 'value.id',
    value: {
      id: 'id',
      label: 'label',
      type: 'type',
      plural: 'Account'
    }
  },
  {
    id: 'value.label',
    value: {
      id: 'id',
      label: 'label',
      type: 'type',
      plural: 'Label'
    }
  },
  {
    id: 'value.env.id',
    value: {
      id: 'id',
      label: 'label',
      type: 'type',
      plural: 'Environment'
    }
  },
  {
    id: 'value.stack.id',
    value: {
      id: 'id',
      label: 'label',
      type: 'type',
      plural: 'Stack'
    }
  }
];

export const ROWS_PER_PAGE = 'Rows per page';
export const FROM_VAL = 'from';
export const SELECT_ALL = 'Select All';
export const CREATE_ACCOUNT_RESULT = 'Create Account Result';
export const CREATE_ACCOUNT_TEXT = 'Create New Account';
export const ACCOUNT_WITH_ID = 'Account with id';
export const MESSAGE_CREATE_SUCCESSFUL = 'was created successfully with all related components';
export const MESSAGE_CREATE_UNSUCCESSFUL = 'The following errors were presented during Account Creation:';
export const PLEASE_PROVIDE_ACCOUNT_INFORMATION = 'Please provide account information.';
export const SAVE = 'Save';

export const ACCOUNT_ID = 'Account ID';
export const ENVIRONMENT = 'Environment';
export const STACK = 'Stack';
export const LAYER = 'Layer';
export const TITLE = 'Title';
export const LABEL = 'Label';
