import * as React from 'react';
import { useMemo, useState, useEffect } from 'react';
import DataTable, { createTheme } from 'react-data-table-component';
import { useMutation } from 'react-query';
import FilterComponent from '../../common-components/filter-componet';
import { useStateCallback } from '../../hooks/use-state-callback';
import { accountsGetAllService } from '../services/accounts-get-all-service';
import '../../tailwind.scss';
import { FROM_VAL, ROWS_PER_PAGE, SELECT_ALL } from '../../constants';
import { VuCustomLoader } from '@avaldigitallabs/adl-rockstars-design-system-vulcanus';
import ButtonMainComponent from '../../common-components/button-main';
import AccountCreateComponent from './account-create-component';
import { useQueries, useQuery, useQueryClient } from 'react-query';
import { ogmioGetAllStackServiceRest } from '../services/ogmio-get-stack-service';
import { ogmioGetAllEnvServiceRest } from '../services/ogmio-get-env-service';

const customStyles = {
  headCells: {
    style: {
      color: 'text-blue'
    }
  },
  columnheader: {
    style: {
      fontSize: 'text-xs font-bold',
      color: 'text-black'
    }
  },
  rows: {
    style: {
      borderTopStyle: 'solid',
      borderTopWidth: '1px',
      borderTopColor: 'bg-vulcanus_cool_gray-500'
    }
  },
  expanderRow: {
    style: {
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      borderBottomColor: 'bg-vulcanus_cool_gray-500'
    }
  },
  headRow: {
    style: {
      fontSize: '15px',
      fontStyle: 'font-bold'
    }
  }
};

export default function ViewAllTable(props: ViewAllProps): JSX.Element {
  const [isCreationOpen, setIsCreationOpen] = useState(false);
  const [selectedKey, setKey] = useState('');
  const [selectedValue, setValue] = useState('');
  const [perPage, setPerPage] = useStateCallback(10);
  const [start, setStart] = useState(true);
  const [dataState, setDataState] = useStateCallback([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [debouncedValue, setDebouncedValue] = useState(selectedValue);
  const [enabled, setEnabled] = useState(true);

  const results = useQueries([
    {
      queryKey: `${props.ogmioToken} Stack`,
      queryFn: ogmioGetAllStackServiceRest,
      enabled: enabled,
      onSuccess: (data: any) => {
        if (data.data.getAllHefestoStackConstants) {
          setEnabled(false);
        }
      }
    },
    {
      queryKey: `${props.ogmioToken} Env`,
      queryFn: ogmioGetAllEnvServiceRest,
      enabled: enabled,
      onSuccess: (data: any) => {
        if (data.data.getAllHefestoEnvConstants) {
          setEnabled(false);
        }
      }
    }
  ]);
  if (results[0].status == 'success' && results[0].status == 'success') {
    safeSetItem('sharedDropdownValues', JSON.stringify(results));
  }
  const filteredItems = useMemo(() => {
    console.log;
    if (dataState) {
      return dataState;
    } else {
      return [];
    }
  }, [dataState, filterText]);

  const accountQueryAll = useMutation(accountsGetAllService, {
    onSuccess: data => {
      try {
        setDataState(data.data?.getPagHefestoAccountsConstants.data);
        setLoading(false);
        setTotalRows(data.data?.getPagHefestoAccountsConstants.totalItems);
      } catch (error) {
        setDataState([]);
        setTotalRows(0);
        setLoading(false);
      }
    },
    onError: (e: string) => {
      console.log({
        title: 'Error',
        message: e,
        active: true
      });
    }
  });

  if (start) {
    accountQueryAll.mutate({
      page: 1,
      limit: 10,
      auth: props.ogmioToken.toString(),
      filter: {
        key: '',
        value: ''
      }
    });
    setStart(false);
  }

  const columns = [
    {
      name: 'Title',
      selector: (account: Account) => account.value.title,
      sortable: true,
      reorder: true,
      hide: 1000
    },
    {
      name: 'Account',
      selector: (account: Account) => account.value.id,
      sortable: true,
      reorder: true,
      hide: 1000
    },
    {
      name: 'Label',
      selector: (account: Account) => account.value.label,
      sortable: true,
      reorder: true
    },
    {
      name: 'Environment',
      selector: (account: Account) => account.value.env.id,
      sortable: true,
      reorder: true
    },
    {
      name: 'Stack',
      selector: (account: Account) => account.value.stack.id,
      sortable: true,
      reorder: true
    }
  ];

  createTheme('ligths', {
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)'
    },
    background: {
      default: '#FFFFFF'
    },
    context: {
      background: '#e3f2fd',
      text: 'rgba(0, 0, 0, 0.87)'
    },
    divider: {
      default: 'rgba(0,0,0,.12)'
    },
    button: {
      default: 'rgba(0,0,0,.54)',
      focus: 'rgba(0,0,0,.12)',
      hover: 'rgba(0,0,0,.12)',
      disabled: 'rgba(0, 0, 0, .18)'
    },
    selected: {
      default: '#e3f2fd',
      text: 'rgba(0, 0, 0, 0.87)'
    },
    highlightOnHover: {
      default: '#EEEEEE',
      text: 'rgba(0, 0, 0, 0.87)'
    },
    striped: {
      default: '#FAFAFA',
      text: 'rgba(0, 0, 0, 0.87)'
    }
  });
  createTheme('darks', {
    text: {
      primary: '#268bd2',
      secondary: '#2aa198'
    },
    background: {
      default: '#002b36'
    },
    context: {
      background: '#cb4b16',
      text: '#FFFFFF'
    },
    divider: {
      default: '#073642'
    },
    button: {
      default: '#2aa198',
      hover: 'rgba(0,0,0,.08)',
      focus: 'rgba(255,255,255,.12)',
      disabled: 'rgba(255, 255, 255, .34)'
    },
    sortFocus: {
      default: '#2aa198'
    }
  });

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(selectedValue), 2000);
    return () => clearTimeout(timer);
  }, [selectedValue]);

  useEffect(() => {
    accountQueryAll.mutate({
      page: 1,
      limit: 10,
      auth: props.ogmioToken.toString(),
      filter: {
        key: selectedKey,
        value: debouncedValue
      }
    });
  }, [debouncedValue]);

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    const handleSubmit = (key: string, value: string) => {
      setLoading(true);
      setKey(key);
      if (value == '') {
        setDebouncedValue(value);
      } else {
        setValue(value);
      }
    };

    return (
      <FilterComponent
        onFilter={(e: React.FormEvent<HTMLInputElement>) => {
          setFilterText(e.currentTarget.value);
        }}
        onClear={handleClear}
        filterText={filterText}
        dataState={dataState}
        setDataState={setDataState}
        onSubmit={handleSubmit}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLoading(true);
    setPerPage(newPerPage);
    accountQueryAll.mutate({
      page,
      limit: newPerPage,
      auth: props.ogmioToken,
      filter: {
        key: '',
        value: ''
      }
    });
  };

  const handlePageChange = async (page: number) => {
    setLoading(true);
    accountQueryAll.mutate({
      page,
      limit: perPage,
      auth: props.ogmioToken,
      filter: {
        key: selectedKey,
        value: selectedValue
      }
    });
  };

  return (
    <div className="flex items-center justify-center h-screen w-screen pt-16">
      <div className="h-screen w-11/12">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">{subHeaderComponentMemo}</div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <ButtonMainComponent methodChange={() => setIsCreationOpen(!isCreationOpen)} Name="+" />
            {isCreationOpen && props.ogmioToken !== '' ? (
              <AccountCreateComponent
                changeMethod={() => setIsCreationOpen(!isCreationOpen)}
                state={isCreationOpen}
                ogmioToken={props.ogmioToken}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="mt-8 flow-root bg-white rounded-xl">
          <div className="m-8">
            <DataTable
              columns={columns}
              data={filteredItems}
              highlightOnHover
              progressPending={loading}
              progressComponent={<VuCustomLoader height={'200'} width={'200'} color={'vulcanus_delft_blue'} />}
              defaultSortFieldId={1}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationComponentOptions={paginationOptions}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              customStyles={customStyles}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export interface Account {
  id: string;
  value: {
    id: string;
    label: string;
    title: string;
    env: {
      id: string;
      type: string;
    };
    stack: {
      id: string;
      type: string;
    };
  };
}

export interface ViewAllProps {
  ogmioToken: string;
}

const paginationOptions = {
  rowsPerPageText: ROWS_PER_PAGE,
  rangeSeparatorText: FROM_VAL,
  selectAllRowsItem: true,
  selectAllRowsItemText: SELECT_ALL
};

function safeSetItem(key: any, value: any) {
  try {
    localStorage.setItem(key, value);
  } catch (e: any) {
    if (e.name === 'QuotaExceededError') {
      console.error('Quota exceeded! Clearing local storage.');
      localStorage.removeItem(key);
      // Try again
      localStorage.setItem(key, value);
    } else {
      console.error('An unknown error occurred: ', e);
    }
  }
}
