import * as React from 'react';
import { Path, UseFormRegister } from 'react-hook-form';
import '../tailwind.scss';

export default function DropdownComponent<T>(props: DropDownProps<T>): JSX.Element {
  const selected = (item: string, initialValue: string) => {
    return 'selected';
  };
  const handleChange = (event: any) => {
    props.changeMethod(event, null || false);
  };
  return (
    <select
      className="mt-2 px-4 mb-0 block w-full rounded-md border-0 text-vulcanus_cool_gray-200 bg-white
            shadow-sm ring-1 ring-inset ring-vulcanus_cool_gray-500 focus:ring-2 focus:ring-inset
            focus:ring-vulcanus_delft_blue sm:text-sm sm:leading-6"
      aria-label="Disabled select example"
      {...props.register(props.route)}
      onChange={handleChange}
    >
      {props.items.map((item: DropDownItem) =>
        props.initialValueSelect != (item.value.label != undefined ? item.value.label : item.value.id) ? (
          <option value={item.id} key={item.id}>
            {item.value.label != undefined ? item.value.label : item.value.id}
          </option>
        ) : (
          <option value={item.id} key={item.id} selected>
            {item.value.label != undefined ? item.value.label : item.value.id}
          </option>
        )
      )}
    </select>
  );
}

export interface DropDownItem {
  id: string;
  value: {
    id: string;
    label: string;
    type: string;
    plural?: string;
  };
}

export interface DropDownProps<T> {
  name: string;
  route: Path<T>;
  register: UseFormRegister<T>;
  items: DropDownItem[];
  initialValueSelect: string;
  changeMethod: (event: React.ChangeEvent<HTMLInputElement>, b: boolean) => void;
}
