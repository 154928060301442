import * as React from 'react';
import { useState } from 'react';
import { useQuery } from 'react-query';
import '../../tailwind.scss';
import { ogmioTokenService } from '../services/ogmio-token-service';
import ViewAllTable from './view-all-table';

export default function BaseComponent(): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const [ogmioToken, setOgmioToken] = useState('');
  window.addEventListener('userToken', myEventHandlingFunction);

  function myEventHandlingFunction(e: any) {
    setOgmioToken(e.detail.tokenValue);
  }

  if (ogmioToken === '') {
    window.dispatchEvent(new CustomEvent('requestToken'));
  }

  return (
    <div className="bg-gray-200 text-gray-300 min-h-screen">
      {ogmioToken !== '' && <ViewAllTable ogmioToken={ogmioToken} />}
    </div>
  );
}
