import { Dialog, Transition } from '@headlessui/react';
import * as React from 'react';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueries } from 'react-query';
import { DropDownItem } from '../../common-components/dropdown-component';
import GenericForm from '../../common-components/generic-form-component';
import { Notification3 } from '../../common-components/notifications';
import TitleComponent from '../../common-components/title-component';

import {
  CREATE_ACCOUNT_RESULT,
  CREATE_ACCOUNT_TEXT,
  ACCOUNT_WITH_ID,
  MESSAGE_CREATE_SUCCESSFUL,
  MESSAGE_CREATE_UNSUCCESSFUL
} from '../../constants';
import '../../tailwind.scss';
import { createFormSections } from '../layouts/account_creation_layout';
import { AccountInput, AccountResponse } from '../account-interfaces';
import accountCreateSchema from '../schemas/account-create-schema';
import { accountCreateService } from '../services/accounts-create-service';
import { VuButton } from '@avaldigitallabs/adl-rockstars-design-system-vulcanus';

export default function AccountCreateComponent(props: CreateAccountProps): JSX.Element {
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const results = JSON.parse(localStorage.getItem('sharedDropdownValues')!);
  const stacksArrayAll = results[0]?.data?.data?.getAllHefestoStackConstants;
  const envArrayAll = results[1]?.data?.data?.getAllHefestoEnvConstants;

  const {
    formState: { errors }
  } = useForm<AccountInput>();

  const newAccount = useMutation(accountCreateService, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: (response: AccountResponse) => {
      setIsLoading(false);
      setShowNotification(true);
      var errorMsgs = "";

      if (response.data?.createHefestoAccountsConstants != null) {
        setIsSuccess(true);
        setNotificationMessage(
          `${ACCOUNT_WITH_ID} ${response.data?.createHefestoAccountsConstants?.id} ${MESSAGE_CREATE_SUCCESSFUL}`
        );
      } else {
        errorMsgs = response.errors[0].message;
        setNotificationMessage(`${MESSAGE_CREATE_UNSUCCESSFUL}\n\n ${errorMsgs}`);
        setIsSuccess(false);
      }
    },
    onError: (data: AccountResponse) => {
      setIsLoading(false);
      // this case actually never happens
    }
  });

  const onSubmit: SubmitHandler<AccountInput> = (data: AccountInput) => {
    console.log(accountCreateSchema.validate(data).error);
    if (accountCreateSchema.validate(data).error === undefined) {
      data.authorization = props.ogmioToken;
      newAccount.mutate({ ...data });
    }
  };
  const creationForm = createFormSections(stacksArrayAll, envArrayAll);

  return (
    <>
      {showNotification && (
        <Notification3
          title={CREATE_ACCOUNT_RESULT}
          message={notificationMessage}
          isSuccess={isSuccess}
          open={showNotification}
          setOpen={setShowNotification}
        />
      )}
      <Transition.Root show={props.state} as={React.Fragment}>
        <Dialog onClose={props.changeMethod} className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="fixed inset-0 transition-opacity">
                <div className="absolute inset-0 bg-vulcanus_cool_gray-500 opacity-75" />
              </div>
            </Transition.Child>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>

            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="z-20 sm:w-full md:w-4/6 lg:w-4/6 modal fade px-8 py-6 mx-4 mt-4 bg-vulcanus_ghost_white-500 shadow-lg fixed top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 overflow-auto h-auto rounded-xl flex flex-col">
                <div className="flex justify-between items-start">
                  <TitleComponent size="l" value={CREATE_ACCOUNT_TEXT} />
                  <VuButton size="xs" text="X" variant="primary" onClick={props.changeMethod}></VuButton>
                </div>
                <GenericForm
                  formSections={creationForm}
                  onClose={onSubmit}
                  isLoading={isLoading}
                  schema={accountCreateSchema}
                />
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
export interface CreateAccountProps {
  state: boolean;
  changeMethod: () => void;
  ogmioToken: string;
}
