const Joi = require('joi');

const accountCreateSchema = Joi.object().keys({
  input: {
    id: Joi.string().required(),
    value: {
      id: Joi.string(),
      label: Joi.string().required().min(5).messages({ 'string.empty': `Label cannot be an empty field` }),
      title: Joi.string().required(),
      env: {
        id: Joi.string().required(),
        type: Joi.string()
      },
      stack: {
        id: Joi.string().required(),
        label: Joi.string()
      }
    }
  }
});

export default accountCreateSchema;
