import axios from 'axios';
import { AccountInput } from '../account-interfaces';
import { DropDownItem } from '../../common-components/dropdown-component';

export const accountCreateService = async (accountInput: AccountInput) => {
  const results = JSON.parse(localStorage.getItem('sharedDropdownValues')!);
  const stacksArrayAll = results[0]?.data?.data?.getAllHefestoStackConstants;
  const envArrayAll = results[1]?.data?.data?.getAllHefestoEnvConstants;
  const config = {
    headers: {
      Authorization: accountInput.authorization,
      'Content-Type': 'application/graphql'
    }
  };
  const input = processInput(accountInput, stacksArrayAll, envArrayAll);
  console.log('input post processed');
  const graphql = JSON.stringify({
    query: createAccountHefestoMutation,
    variables: input
  });

  const ULR = process.env.GRAPHQLURL !== undefined ? process.env.GRAPHQLURL : '';
  const { data: response } = await axios.post(ULR, graphql, config);
  return response;
};

const createAccountHefestoMutation = `
mutation createHefestoAccountsConstants($input: CreateHefestoAccountsConstantsInput!){
  createHefestoAccountsConstants(input: $input){
    id
    value{
        label
        title
        env{
            id
            type
        }
        stack{
            id
            label
        }
    }
  }
}
`;

function processInput(
  accountInput: AccountInput,
  stacksArray: DropDownItem[],
  envsArray: DropDownItem[]
): AccountInput {
  const input = { ...accountInput };
  input.authorization = '';

  const stackResult = stacksArray.filter(word => word.id == input.input.value.stack.id);
  const envResult = envsArray.filter(word => word.id == input.input.value.env.id);

  input.input.id = input.input.id.trim();
  input.input.value.id = input.input.id.trim();
  input.input.value.label = input.input.value.label.trim();
  input.input.value.title = input.input.value.title.trim();
  input.input.value.stack = stackResult[0].value;
  input.input.value.env = envResult[0].value;

  return input;
}
