import axios from 'axios';
import { getPagHefestoAccountsConstants } from '../graphql/queries';

export const accountsGetAllService = async (accountsGetAllProps: AccountsGetAllProps) => {
  const config = {
    headers: { Authorization: accountsGetAllProps.auth, 'Content-Type': 'application/graphql' }
  };
  const graphql = JSON.stringify({
    query: getPagHefestoAccountsConstants,
    variables: {
      input: {
        limit: accountsGetAllProps.limit,
        page: accountsGetAllProps.page,
        filter: accountsGetAllProps.filter
      }
    }
  });

  const ULR = process.env.GRAPHQLURL !== undefined ? process.env.GRAPHQLURL : '';
  const { data: response } = await axios.post(ULR, graphql, config);
  return response;
};

export interface AccountsGetAllProps {
  auth: string;
  page: number;
  limit: number;
  filter: {
    key: string;
    value: string;
  };
}
