import { joiResolver } from '@hookform/resolvers/joi';
import * as Joi from 'joi';
import { useState } from 'react';
import * as React from 'react';
import { Path, useForm, UseFormRegister } from 'react-hook-form';
import { AccountInput } from '../components/account-interfaces';
import { SAVE } from '../constants';
import DropdownComponent, { DropDownItem } from './dropdown-component';
import InputComponent from './input-component';
import ToggleComponent from './toggle-component';
import { VuButton, VuCustomLoader } from '@avaldigitallabs/adl-rockstars-design-system-vulcanus';

export interface FormField {
  id: string;
  name: string;
  label: string;
  type: string;
  autoComplete: string;
  required: boolean;
  changeMethod: (event: React.ChangeEvent<HTMLInputElement>, untoggable: boolean) => void;
  route: Path<AccountInput>;
  placeholder?: string;
  options?: DropDownItem[];
  colSpan?: number;
  isHidden?: boolean;
  initialValueString?: string;
  initialValueSelect?: string;
  initialValueBoolean?: boolean;
  expectedValueBoolean?: boolean;
  locked?: boolean;
  section: string;
  example?: string;
}

export interface FormCategory {
  id: string;
  title: string;
  col_span: string;
}

export interface FormSection {
  title: string;
  description: string;
  fields: FormField[];
  categories: FormCategory[];
}

export interface GenericFormProps {
  formSections: FormSection[];
  onClose: (formData: AccountInput) => void;
  isLoading: boolean;
  schema: Joi.ObjectSchema;
}

const GenericForm: React.FC<GenericFormProps> = ({ formSections, onClose, isLoading, schema }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm<AccountInput>({});
  const closeHandler = async (data: AccountInput) => {
    setIsSubmitting(true);
    await onClose(data);
    setIsSubmitting(false);
  };
  return (
    <form className="space-y-4" onSubmit={handleSubmit(closeHandler)}>
      <div className="space-y-4">
        {formSections.map((section: FormSection, index: number) => (
          <div key={index}>{genericSection(section, register)}</div>
        ))}
      </div>
      <div className="pt-5">
        <label className="flex justify-start text-vulcanus_cool_gray-500">Fields with * are required</label>
        <div className="flex justify-end">
          {isLoading ? (
            <VuCustomLoader height={50} width={50} color={'vulcanus_ghost_white-500'} />
          ) : (
            <VuButton variant="primary" text={SAVE} type="submit" disabled={isSubmitting} />
          )}
        </div>
      </div>
    </form>
  );
};

export default GenericForm;

function genericField(section: FormSection, register: UseFormRegister<AccountInput>, category: FormCategory) {
  return (
    <div className={`grid grid-cols-1 gap-x-6 sm:grid-cols-${category.col_span}`}>
      {section.fields.map(
        (field: FormField, fieldIndex: number) =>
          (!field.isHidden || false) &&
          field.section === category.id && (
            <div className={`sm:col-span-1`}>
              <div key={fieldIndex} className={`sm:col-span-1 md:col-span-${field.colSpan ? field.colSpan : 1} mt-6`}>
                <label htmlFor={field.id} className="block text-sm font-medium leading-6 text-vulcanus_cool_gray-500">
                  {field.label}
                </label>
                <div>
                  {field.type === 'select' ? (
                    <DropdownComponent
                      name={field.name}
                      route={field.route}
                      register={register}
                      items={field.options || []}
                      initialValueSelect={field.initialValueSelect || ''}
                      changeMethod={field.changeMethod}
                    />
                  ) : field.type === 'checkbox' ? (
                    <ToggleComponent
                      route={field.route}
                      register={register}
                      name={field.name}
                      changeMethod={field.changeMethod}
                      disabled={field.locked || false}
                      notToggleable={field.locked ?? false}
                      isChecked={field.initialValueBoolean || false}
                      expectedValueBoolean={field.expectedValueBoolean || null}
                    />
                  ) : (
                    <>
                      <InputComponent
                        name={field.name}
                        register={register}
                        route={field.route}
                        locked={field.locked || false}
                        initialValue={field.initialValueString || ''}
                        required={field.required}
                        type={field.type}
                        placeholder={field.example ? field.example : ''}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          )
      )}
    </div>
  );
}

function genericSection(section: FormSection, register: UseFormRegister<AccountInput>) {
  return (
    <div>
      {section.categories.map((category: FormCategory, fieldIndex: number) => (
        <div className="border-b border-gray-900/10 pb-8">
          <h2 className="text-base font-semibold leading-7 text-gray-900 my-2">{category.title}</h2>
          {genericField(section, register, category)}
        </div>
      ))}
    </div>
  );
}
