import * as React from 'react';
import '../tailwind.scss';
import { VuButton } from '@avaldigitallabs/adl-rockstars-design-system-vulcanus';

export default function ButtonMainComponent(props: ButtonMainProp): JSX.Element {
  return (
    <VuButton
      size="sm"
      variant="primary"
      text={props.Name}
      onClick={props.methodChange}
      data-bs-toggle="modal"
      data-bs-target="#exampleModal"
    />
  );
}
export interface ButtonMainProp {
  methodChange: () => void;
  Name: string;
}
