import axios from 'axios';
import * as React from 'react';

const axiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/graphql'
  }
});

const getAllHefestoStackQuery = `query getAllHefesto$1Constants{
  getAllHefesto$1Constants{
    id
    value {
      id
      label
    }
  }
}`;

export const ogmioGetAllStackServiceRest = async ({ queryKey }: { queryKey: string[] }) => {
  const authorization = queryKey[0].split(' ')[0];
  const constant = queryKey[0].split(' ')[1];
  const graphql = JSON.stringify({
    query: getAllHefestoStackQuery.replace(new RegExp('\\$1', 'g'), constant),
    variables: {}
  });
  const url = process.env.GRAPHQLURL || '';
  try {
    const { data } = await axiosInstance.post(url, graphql, {
      headers: {
        Authorization: authorization
      }
    });
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
